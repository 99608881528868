var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v(_vm._s(_vm.$t('c:api-request-log:Api Request Logs Statistics')))]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-1",attrs:{"cols":"1"}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('c:api-request-log:From date'),"dense":"","outlined":"","readonly":""},model:{value:(_vm.fromDateFilter),callback:function ($$v) {_vm.fromDateFilter=$$v},expression:"fromDateFilter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.fromDateFilter),callback:function ($$v) {_vm.fromDateFilter=$$v},expression:"fromDateFilter"}})],1)],1),_c('v-col',{staticClass:"mr-3",attrs:{"cols":"1"}},[_c('v-menu',{ref:"toDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('c:api-request-log:To date'),"dense":"","outlined":"","readonly":""},model:{value:(_vm.toDateFilter),callback:function ($$v) {_vm.toDateFilter=$$v},expression:"toDateFilter"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.toDateMenu = false}},model:{value:(_vm.toDateFilter),callback:function ($$v) {_vm.toDateFilter=$$v},expression:"toDateFilter"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.search}},[_vm._v(" "+_vm._s(_vm.$t('Search'))+" ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" "+_vm._s(_vm.$t('c:api-request-log:Clear'))+" ")])],1)],1),(_vm.loading)?_c('v-row',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100"}})],1):_vm._e(),(_vm.groupBySiteIdRawRouteData)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('h3',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('c:api-request-log:Grouped by Site ID and Raw Route')))]),_c('v-btn',{attrs:{"color":"primary","small":"","loading":_vm.exportJsonLoading},on:{"click":function($event){return _vm.handleJsonDownload(_vm.groupBySiteIdRawRouteData, 'grouped_by_site_id_and_raw_route')}}},[_vm._v(" "+_vm._s(_vm.$t('Download JSON'))+" ")])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.groupBySiteIdRawRouteHeaders,"items":_vm.groupBySiteIdRawRouteData,"item-key":"id","dense":"","outlined":"","items-per-page":25,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250, -1] }},scopedSlots:_vm._u([{key:"item.maxTotalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.maxTotalRequestTime)+"ms ("+_vm._s(parseFloat(item.maxTotalRequestTime / 1000).toFixed(2))+"s) ")]}},{key:"item.minTotalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minTotalRequestTime)+"ms ("+_vm._s(parseFloat(item.minTotalRequestTime / 1000).toFixed(2))+"s) ")]}},{key:"item.avgTotalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.avgTotalRequestTime)+"ms ("+_vm._s(parseFloat(item.avgTotalRequestTime / 1000).toFixed(2))+"s) ")]}}],null,false,417240775)})],1):_vm._e(),(_vm.groupByRawRouteData)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('h3',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('c:api-request-log:Grouped by Raw Route')))]),_c('v-btn',{attrs:{"color":"primary","small":"","loading":_vm.exportJsonLoading},on:{"click":function($event){return _vm.handleJsonDownload(_vm.groupByRawRouteData, 'grouped_by_raw_route')}}},[_vm._v(" "+_vm._s(_vm.$t('Download JSON'))+" ")])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.groupByRawRouteHeaders,"items":_vm.groupByRawRouteData,"item-key":"id","dense":"","outlined":"","items-per-page":25,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250, -1] }},scopedSlots:_vm._u([{key:"item.maxTotalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.maxTotalRequestTime)+"ms ("+_vm._s(parseFloat(item.maxTotalRequestTime / 1000).toFixed(2))+"s) ")]}},{key:"item.minTotalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minTotalRequestTime)+"ms ("+_vm._s(parseFloat(item.minTotalRequestTime / 1000).toFixed(2))+"s) ")]}},{key:"item.avgTotalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.avgTotalRequestTime)+"ms ("+_vm._s(parseFloat(item.avgTotalRequestTime / 1000).toFixed(2))+"s) ")]}}],null,false,417240775)})],1):_vm._e(),(_vm.latencyLogs)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('h3',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.$t('c:api-request-log:Latency logs more than'))+" 3000 ms")]),_c('v-btn',{attrs:{"color":"primary","small":"","loading":_vm.exportJsonLoading},on:{"click":function($event){return _vm.handleJsonDownload(_vm.latencyLogs, 'latency_logs')}}},[_vm._v(" "+_vm._s(_vm.$t('Download JSON'))+" ")])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.latencyLogsHeaders,"items":_vm.latencyLogs,"item-key":"id","dense":"","outlined":"","items-per-page":25,"footer-props":{ showFirstLastPage: true, 'items-per-page-options': [10, 25, 50, 100, 250, -1] }},scopedSlots:_vm._u([{key:"item.totalRequestTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalRequestTime)+"ms ("+_vm._s(parseFloat(item.totalRequestTime / 1000).toFixed(2))+"s) ")]}}],null,false,1242880617)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }