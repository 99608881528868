








































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { downloadJSON } from '@/utils/fileUtils.ts'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private loading = false
  private exportJsonLoading = false

  private fromDateFilter = null
  private toDateFilter = null

  private fromDateMenu = false
  private toDateMenu = false

  private groupBySiteIdRawRouteData = null
  private groupByRawRouteData = null
  private latencyLogs = null

  private groupBySiteIdRawRouteHeaders = [
    { text: this.$t('c:api-request-log:Site ID'), value: 'siteId' },
    { text: this.$t('c:api-request-log:Raw route'), value: 'rawRoute' },
    { text: this.$t('c:api-request-log:Max. Total request time'), value: 'maxTotalRequestTime' },
    { text: this.$t('c:api-request-log:Min. Total request time'), value: 'minTotalRequestTime' },
    { text: this.$t('c:api-request-log:Avg. Total request time'), value: 'avgTotalRequestTime' },
    { text: this.$t('c:api-request-log:Total call counts'), value: 'callCount' },
  ]

  private groupByRawRouteHeaders = [
    { text: this.$t('c:api-request-log:Raw route'), value: 'rawRoute' },
    { text: this.$t('c:api-request-log:Max. Total request time'), value: 'maxTotalRequestTime' },
    { text: this.$t('c:api-request-log:Min. Total request time'), value: 'minTotalRequestTime' },
    { text: this.$t('c:api-request-log:Avg. Total request time'), value: 'avgTotalRequestTime' },
    { text: this.$t('c:api-request-log:Total call counts'), value: 'callCount' },
  ]

  private latencyLogsHeaders = [
    { text: this.$t('c:api-request-log:Site ID'), value: 'siteId' },
    { text: this.$t('c:api-request-log:Api key'), value: 'apiKey' },
    { text: this.$t('c:api-request-log:Raw route'), value: 'rawRoute' },
    { text: this.$t('c:api-request-log:Total request time'), value: 'totalRequestTime' },
  ]

  private clear() {
    this.groupBySiteIdRawRouteData = null
    this.groupByRawRouteData = null
    this.fromDateFilter = null
    this.toDateFilter = null
    this.fromDateMenu = false
    this.toDateMenu = false
  }

  private search() {
    this.loading = true
    const data = {
      fromDate: this.fromDateFilter,
      toDate: this.toDateFilter,
    }
    this.$axios
      .post(this.url, data)
      .then((response) => {
        this.groupBySiteIdRawRouteData = response.data.groupBySiteIdRawRouteData
        this.groupByRawRouteData = response.data.groupByRawRouteData
        this.latencyLogs = response.data.latencyLogs
      })
      .catch((error) => {
        console.error('Error fetching report:', error)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private get url() {
    return '/v4/sysop/api-request-logs'
  }

  private handleJsonDownload(data, fileName) {
    downloadJSON(data, fileName, this.setExportJsonLoading)
  }

  private setExportJsonLoading(loading: boolean): void {
    this.exportJsonLoading = loading
  }
}
